import './style.css'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Container, Row, Col, Hidden, Visible } from 'react-grid-system'
import { injectIntl } from 'react-intl'

import Button from '../../components/Button'
import I18nLink from '../../components/I18nLink'
import Inline from '../../components/Inline'
import Logo from '../../components/Logo'
import Space from '../../components/Space'
import Item from './components/Item'
import SubItem from './components/SubItem'
import BurgerButton from './components/BurgerButton'

const GATSBY_APP_URL = process.env.GATSBY_APP_URL

const desktopContainerClass = classNames({
  'navigation-desktop-container': true,
})

const desktopLeftClass = classNames({
  'navigation-desktop-left': true,
})

const desktopRightClass = classNames({
  'navigation-desktop-right': true,
})

const navigationMobileContainerClass = classNames({
  'navigation-mobile-container': true,
})

const mobileContentClass = classNames({
  'navigation-mobile-content': true,
})

class Navigation extends Component {

  constructor(props) {
    super(props)

    this.state = {
      atTop: true,
      isMenuToggled: false,
      isTransparent: props.isTransparent,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }


  // Handlers
  handleScroll = () => {
    const { isTransparent } = this.props
    const { isMenuToggled } = this.state

    // Full browser support
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
    const atTop = scrollPosition === 0

    this.setState({
      atTop: atTop,
      isTransparent: isTransparent && atTop && !isMenuToggled,
    })
  }

  handleBurgerButtonClick = () => {
    const { isMenuToggled, atTop } = this.state
    const { isTransparent } = this.props
    const shouldBecomeTransparent = isMenuToggled && isTransparent && atTop

    this.setState({
      isMenuToggled: !isMenuToggled,
      isTransparent: shouldBecomeTransparent,
    })
  }


  // Render
  render() {
    const {
      atTop,
      isMenuToggled,
      isTransparent,
    } = this.state
    const {
      children,
      isFixed,
    } = this.props

    const navigationContainerClass = classNames({
      'navigation-container': true,
      'navigation-container-maxed': isMenuToggled,
      'navigation-container-shadowed': !isMenuToggled && !atTop,
      'navigation-container-transparent': isTransparent,
      'navigation-container-fixed': isFixed,
    })

    return (
      <div className={navigationContainerClass}>
        <Hidden xs sm md>
          <Container >
            <Row>
              <Col xs={12}>
                {this.renderDesktop()}
              </Col>
            </Row>
          </Container>
        </Hidden>

        <Visible xs sm md>
          {this.renderMobile()}
        </Visible>

        {
          children &&
          <Hidden xs sm md>
            {children}
          </Hidden>
        }
      </div>
    )
  }

  renderDesktop = () => {
    return (
      <div className={desktopContainerClass}>
        <div className={desktopLeftClass}>
          {this.renderLogo()}
          {this.renderItem('features')}
          {this.renderItem('pricing')}
          {this.renderItem('documentation')}
          {this.renderItem('security')}
        </div>
        <div className={desktopRightClass}>
          {this.renderSignInLink()}
          <Inline type="s" />
          {this.renderSignUpButton()}
        </div>
      </div>
    )
  }

  renderMobile = () => {
    const {
      isMenuToggled,
      isTransparent,
    } = this.state

    return (
      <Fragment>
        <div className={navigationMobileContainerClass}>
          {this.renderLogo()}
          <BurgerButton
            isTransparent={isTransparent}
            isToggled={isMenuToggled}
            onClick={this.handleBurgerButtonClick}
          />
        </div>
        {isMenuToggled && this.renderMobileMenu()}
      </Fragment>
    )
  }

  renderMobileMenu = () => {
    return (
      <div className={mobileContentClass}>
        {this.renderSignInLink()}
        <Space type="xs" />
        {this.renderSignUpButton()}
        <Space type="xl" />
        {this.renderItem('features')}
        <Space type="s" />
        {this.renderItem('ai')}
        <Space type="s" />
        {this.renderItem('integrations')}
        <Space type="s" />
        {this.renderItem('pricing')}
        <Space type="s" />
        {this.renderItem('documentation')}
        <Space type="s" />
        {this.renderItem('security')}
      </div>
    )
  }

  renderLogo = () => {
    const {
      isTransparent,
    } = this.state

    return (
      <I18nLink to="/">
        <Logo
          height={60}
          color={isTransparent ? 'white' : 'red'}
        />
      </I18nLink>
    )
  }

  renderItem = (type) => {
    const { isTransparent } = this.state
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    switch (type) {
      case 'features':
        return (
          <Item
            text={formatMessage({ id: 'navigation.links.features' })}
            uri={'/features'}
            isReversed={isTransparent}
          >
            <SubItem text={formatMessage({ id: 'navigation.links.features' })} uri='/features' />
            <Space type="xs" />
            <SubItem text={formatMessage({ id: 'navigation.links.ai' })} uri='/artificial-intelligence' />
            <Space type="xs" />
            <SubItem text={formatMessage({ id: 'navigation.links.integrations' })} uri='/integrations' />
          </Item>
        )
      case 'ai':
        return (<Item text={formatMessage({ id: 'navigation.links.ai' })} uri={'/artificial-intelligence'} isReversed={isTransparent} />)
      case 'integrations':
        return (<Item text={formatMessage({ id: 'navigation.links.integrations' })} uri={'/integrations'} isReversed={isTransparent} />)
      case 'pricing':
        return (<Item text={formatMessage({ id: 'navigation.links.pricing' })} uri={'/pricing'} isReversed={isTransparent} />)
      case 'documentation':
        return (
          <Item
            text={formatMessage({ id: 'navigation.links.documentation' })}
            uri={'/documentation'}
            isReversed={isTransparent}
          >
            <SubItem text={formatMessage({ id: 'navigation.links.documentation.dataManagement' })} uri='/data-management' />
            <Space type="xs" />
            <SubItem text={formatMessage({ id: 'navigation.links.documentation.dataProtection' })} uri='/data-protection' />
            <Space type="xs" />
            <SubItem text={formatMessage({ id: 'navigation.links.documentation.privacyPolicy' })} uri='/privacy-policy' />
          </Item>
        )
      case 'security':
        return (<Item text={formatMessage({ id: 'navigation.links.about' })} uri={'/about'} isReversed={isTransparent} />)
      default:
        return null
    }
  }

  renderSignInLink = () => {
    const { isTransparent } = this.state
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Item
        text={formatMessage({ id: 'navigation.links.connection' })}
        url={`${GATSBY_APP_URL}/sign-in`}
        isReversed={isTransparent}
      />
    )
  }

  renderSignUpButton = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <a href={`${GATSBY_APP_URL}/sign-up?utm_source=wuha&utm_medium=cta&utm_content=header`}>
        <Button
          text={formatMessage({ id: 'navigation.button.tryIt' })}
          color="red"
          isShadowed
        />
      </a>
    )
  }
}

Navigation.propTypes = {
  isTransparent: PropTypes.bool,
  isFixed: PropTypes.bool,
  children: PropTypes.node,
  intl: PropTypes.object,
}

Navigation.defaultProps = {
  isTransparent: false,
  isFixed: false,
  children: undefined,
}

export default injectIntl(Navigation)
