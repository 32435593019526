import './style.css'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import axios from 'axios'

import Image from '../../../../components/Image'
import Inline from '../../../../components/Inline'
import statusGreenImage from '../../../../img/icons/icon_status_green.svg'
import statusRedImage from '../../../../img/icons/icon_status_red.svg'

const STATUS_URL = 'https://status.wuha.io/api/v1/incidents'

const serviceStatusClass = classNames({
  'footer-service-status': true,
})

const serviceStatusTextClass = classNames({
  'footer-service-status-text': true,
})

class ServiceStatus extends Component {

  constructor(props) {
    super(props)

    this.state = {
      isDown: false,
    }
  }

  componentDidMount() {
    axios
      .get(STATUS_URL)
      .then((response) => {
        const {
          data: {
            data,
          } = {},
        } = response

        const isDown = data && data.length > 0 && data[0].status !== 4
        this.setState({
          isDown,
        })
      })
      .catch(error => {
        this.setState({
          isDown: true,
        })
      })
  }


  // Render
  render() {
    const {
      isDown,
    } = this.state
    const {
      text,
      url,
    } = this.props

    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={serviceStatusClass}>
          <Image
            src={isDown ? statusRedImage : statusGreenImage}
            alt="status"
          />
          <Inline type="xxs" />
          <p className={serviceStatusTextClass}>
            {text}
          </p>
        </div>
      </a>
    )
  }
}

ServiceStatus.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  isDown: PropTypes.bool,
}

ServiceStatus.defaultProps = {
  text: undefined,
  url: undefined,
  isDown: false,
}

export default ServiceStatus
