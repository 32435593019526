import './style.css'
import React, { Component, Fragment } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Col, Visible, Hidden } from 'react-grid-system'

import Logo from '../../components/Logo'
import Space from '../../components/Space'
import linkedInImage from '../../img/icons/icon_social_linkedin.svg'
import facebookImage from '../../img/icons/icon_social_facebook.svg'
import twitterImage from '../../img/icons/icon_social_twitter.svg'
import ServiceStatus from './components/ServiceStatus'
import Copyright from './components/Copyright'
import Separator from './components/Separator'
import LinkHeader from './components/LinkHeader'
import LinkItem from './components/LinkItem'
import SocialItem from './components/SocialItem'
import LanguageItem from './components/LanguageItem'

const STATUS_URL = 'https://status.wuha.io/'
const LINKEDIN_URL = 'https://www.linkedin.com/company/wuha/'
const FACEBOOK_URL = 'https://www.facebook.com/wuhacorp/'
const TWITTER_URL = 'https://twitter.com/wuhacorp/'

const containerClass = classNames({
  'footer-container': true,
})

class Footer extends Component {

  render() {
    return (
      <Fragment>
        <Separator />
        <Container>
          <Space type="xl" />
          <Row>
            <Hidden xs sm>
              {this.renderLeftPane()}
            </Hidden>

            {this.renderRightPane()}

            <Visible xs sm>
              {this.renderLeftPane()}
            </Visible>
          </Row>
        </Container>
      </Fragment>
    )
  }

  renderLeftPane = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12} md={6}>
        <div className={containerClass}>
          <Logo
            height={60}
          />
          <h2>
            {formatMessage({ id: 'footer.header.tagline' })}
          </h2>
          <Space type="m" />
          <ServiceStatus
            url={STATUS_URL}
            text={formatMessage({ id: 'footer.header.serviceStatus' })}
          />
          <Space type="s" />
          <Copyright
            text={formatMessage({ id: 'footer.header.copyright' })}
          />
          <Space type="l" />
        </div>
      </Col>
    )
  }

  renderRightPane = () => {
    return (
      <Fragment>
        {this.renderLinksProduct()}
        {this.renderLinksCompany()}
        {this.renderLinksSocialLanguages()}
      </Fragment>
    )
  }

  renderLinksProduct = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12} md={2}>
        <div className={containerClass}>
          <LinkHeader
            text={formatMessage({ id: 'footer.links.product.header' })}
          />
          <Space type="m" />
          <LinkItem
            text={formatMessage({ id: 'footer.links.product.items.features' })}
            uri={'/features'}
          />
          <Space type="s" />
          <LinkItem
            text={formatMessage({ id: 'footer.links.product.items.ai' })}
            uri={'/artificial-intelligence'}
          />
          <Space type="s" />
          <LinkItem
            text={formatMessage({ id: 'footer.links.product.items.integrations' })}
            uri={'/integrations'}
          />
          <Space type="s" />
          <LinkItem
            text={formatMessage({ id: 'footer.links.product.items.pricing' })}
            uri={'/pricing'}
          />
          <Space type="s" />
          <LinkItem
            text={formatMessage({ id: 'footer.links.product.items.dataManagement' })}
            uri={'/data-management'}
          />
          <Space type="s" />
          <LinkItem
            text={formatMessage({ id: 'footer.links.product.items.dataProtection' })}
            uri={'/data-protection'}
          />
          <Space type="l" />
        </div>
      </Col>
    )
  }

  renderLinksCompany = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Col sm={12} md={2}>
        <div className={containerClass}>
          <LinkHeader
            text={formatMessage({ id: 'footer.links.company.header' })}
          />
          <Space type="m" />
          <LinkItem
            text={formatMessage({ id: 'footer.links.company.items.about' })}
            uri={'/about'}
          />
          <Space type="s" />
          <LinkItem
            text={formatMessage({ id: 'footer.links.company.items.blog' })}
            uri={'/blog'}
          />
          <Space type="s" />
          <LinkItem
            text={formatMessage({ id: 'footer.links.company.items.pp' })}
            uri={'/privacy-policy'}
          />
          <Space type="s" />
          <LinkItem
            text={formatMessage({ id: 'footer.links.company.items.tos' })}
            uri={'/terms'}
          />
          <Space type="l" />
        </div>
      </Col>
    )
  }

  renderLinksSocialLanguages = () => {
    return (
      <Col sm={12} md={2}>
        <div className={containerClass}>
          {this.renderSocial()}
          {this.renderLanguages()}
          <Space type="l" />
        </div>
      </Col>
    )
  }

  renderSocial = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props
    const socialItemsClass = {
      display: 'flex',
      alignItems: 'center',
    }

    return (
      <Fragment>
        <LinkHeader
          text={formatMessage({ id: 'footer.links.social.header' })}
        />
        <Space type="s" />
        <div style={socialItemsClass}>
          <SocialItem
            path={linkedInImage}
            url={LINKEDIN_URL}
            alt="linkedin"
          />
          <SocialItem
            path={facebookImage}
            url={FACEBOOK_URL}
            alt="facebook"
          />
          <SocialItem
            path={twitterImage}
            url={TWITTER_URL}
            alt="twitter"
          />
        </div>
        <Space type="m" />
      </Fragment>
    )
  }

  renderLanguages = () => {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Fragment>
        <LinkHeader
          text={formatMessage({ id: 'footer.links.language.header' })}
        />
        <Space type="m" />
        <LanguageItem
          text={formatMessage({ id: 'footer.links.language.items.english' })}
          lang={'en'}
        />
        <Space type="s" />
        <LanguageItem
          text={formatMessage({ id: 'footer.links.language.items.french' })}
          lang={'fr'}
        />
      </Fragment>
    )
  }
}

export default injectIntl(Footer)
